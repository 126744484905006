import { MutableLicenserProps, TypeReairLicenserReplyStatus } from 'Api';
import React, { useCallback, useState } from 'react';
import { toDateString } from 'Utils';
import CommentThread from 'ViewComponents/CommentThread';
import { ButtonM } from '../Parts/Buttons';
import { RadiobtnOKNG } from '../Parts/Checkboxes';
import { InputTextArea } from '../Parts/InputText';

function NeedCommentError() {
  return (
    <span className="NeedCommentError">要確認の際はコメントをご記入ください</span>
  )
}

export default function AnswerSection({
  licenser,
  statusEvent,
}: MutableLicenserProps) {
  const [answer, setAnswer] = useState<string>(licenser.replyStatus==='権利確認メール返信OK'? "OK" : (
    licenser.replyStatus==='権利確認メール返信要確認'? "NG" : ''
  ));
  const [comment, setComment] = useState('');
  const [commentError, setCommentError] = useState(false);

  const onClick = useCallback(() => {
    if (!answer) return;
    if (answer === 'NG' && !comment) {
      setCommentError(true);
      return;
    }
    const newStatus: TypeReairLicenserReplyStatus =
      answer === 'OK' ? '権利確認メール返信OK' : '権利確認メール返信要確認';
    statusEvent.emit('status', {
      newStatus,
      comment: comment,
    });
  }, [answer, statusEvent, comment, setCommentError]);
  const onCommentChange = useCallback(
    (ev: any) => {
      setComment(ev.target.value);
    },
    [setComment],
  );
  return (
    <form className="AnswerSection">
      <div className="AnswerSection-Notice">
        内容をご確認いただけましたら<br/>
        下記ボタンを選択の上、送信して<br/>
        いただきますようお願いいたします。<br/>
        なお「要確認」の際は コメントやご質問をご記入ください。<br/>
      </div>
      <RadiobtnOKNG answer={answer} setAnswer={setAnswer} />
      <div className="AnswerSection-Notice2">
      {toDateString(licenser.dueDate!)}までに<br/>
      ご回答をお願いいたします。<br/>
      </div>
      <CommentThread licenser={licenser}/>
      <InputTextArea
        title="コメントはこちら"
        placeholder="コメントを書く"
        value={comment}
        onChange={onCommentChange}
      />
      {commentError? <NeedCommentError/> : <></>}
      <div className="Spacer"></div>
      <ButtonM title="送信" onClick={onClick} color="red" disabled={!answer} />
    </form>
  );
}
