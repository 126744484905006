import React from 'react';
import './Checkboxes.scss';

function RadiobtnOKNG({answer, setAnswer}:any) {
  return <div className="RadiobtnOKNG-Wrapper">
    <label className="RadiobtnNG">
      <input type="radio" name="okng" value="NG" checked={answer==="NG"} onChange={()=>{setAnswer("NG");}}></input>
      <span className="RadiobtnNG-Mark"></span>
    </label>
    <label className="RadiobtnOK">
      <input type="radio" name="okng" value="OK" checked={answer==="OK"} onChange={()=>{setAnswer("OK");}}></input>
      <span className="RadiobtnOK-Mark"></span>    
    </label>
  </div>;
}
function Checkbox(props: {label: string, checked?:boolean, value?:any, onChange? : any} ) {
  return <>
  <label className="Checkbox">
    <input type="checkbox" name="checkbox" {...props}></input>
    <span className="Checkbox-Mark"></span>
    {props.label}
  </label>
  </>;
}

export {
  Checkbox, RadiobtnOKNG
}