import React, { useCallback } from 'react';
import ContactForm from 'ViewComponents/ContactForm';
import Footer from 'ViewComponents/Footer';
import FooterCopyright from 'ViewComponents/Footer/FooterCopyright';
import FooterNotice from 'ViewComponents/Footer/FooterNotice';
import Header from 'ViewComponents/Header';
import HeaderTitle from 'ViewComponents/Header/HeaderTitle';
import { Main } from 'ViewComponents/Main';
import { SummaryCard } from 'ViewComponents/SummaryCard';
import { TextParagraph, TextThanks } from 'ViewComponents/TextParagraph';
import { MutableLicenserProps } from 'Api';
import ProjectGuide , {ProjectGuideCenter} from 'ViewComponents/ProjectGuide';

export function NoChargeThanksSubmitted({
  licenser,
  statusEvent,
}: MutableLicenserProps) {
  return (
    <div>
      <Header>
        <HeaderTitle />
      </Header>
      <Main>
        <ProjectGuideCenter>
        <TextParagraph>
          {licenser.belongOffice!.officeName}
          <br />
          {licenser.belongOffice!.staffName}様
          <br />
          <br />
          ご協力ありがとうございました。
          <br />
          ご案内メールは破棄していただけますよう<br />
          お願い申し上げます。
          <br />
          <br />
          </TextParagraph>
        </ProjectGuideCenter>
      </Main>
      <Footer>
        <FooterNotice licenser={licenser} />
        <FooterCopyright>　©︎ 株式会社シュヴァン</FooterCopyright>
      </Footer>
    </div>
  );
}

export function NoChargeThanks({
  licenser,
  statusEvent,
}: MutableLicenserProps) {
  const onSubmit = useCallback(
    (message: string) => {
      statusEvent.emit('contactSubmit', message);
    },
    [statusEvent],
  );
  return (
    <div className="ChargeThanks">
      <Header>
        <HeaderTitle />
      </Header>
      <Main>
        <ProjectGuideCenter>
        <TextParagraph>
          {licenser.belongOffice!.officeName}
          <br />
          {licenser.belongOffice!.staffName}様
          <br />
          <br />
          担当に関しまして
          <br />
          ご回答ありがとうございました。
          <br />
          本件のご担当でないとのこと承りました。
          <br />
          恐れ入りますが、ご存知の範囲で結構ですので、現在のご担当の方の情報をご教示くださいますと幸いです。
          <br />
          <br />
          </TextParagraph>
        </ProjectGuideCenter>
        <SummaryCard licenser={licenser} />
        <ContactForm onSubmit={onSubmit} />
      </Main>
      <Footer>
        <FooterNotice licenser={licenser} />
        <FooterCopyright>　©︎ 株式会社シュヴァン</FooterCopyright>
      </Footer>
    </div>
  );
}

export function OKChargeThanks({
  licenser,
  statusEvent,
}: MutableLicenserProps) {
  return (
    <div className="ChargeThanks">
      <Header>
        <HeaderTitle />
      </Header>
      <Main>
        <ProjectGuideCenter>
          <TextParagraph>
          {licenser.belongOffice!.officeName}
          <br />
          {licenser.belongOffice!.staffName}様
          <br />
          <br />
          担当に関しまして
          <br />
          ご回答ありがとうございました。
          <br />
          本件のご担当とのこと承りました。
          <br />
          改めて、先ほどお送りしたメールアドレス宛に詳細内容をメール差し上げます。
          <br />
          <br />
          </TextParagraph>
        </ProjectGuideCenter>
        <SummaryCard licenser={licenser} />
        <TextParagraph>
          本件のご担当とのこと承りました。
          <br />
          改めて詳細内容のご案内をメールにて差し上げます。
          <br />
        </TextParagraph>
      </Main>
      <Footer>
        <FooterNotice licenser={licenser} />
        <FooterCopyright>　©︎ 株式会社シュヴァン</FooterCopyright>
      </Footer>
    </div>
  );
}
