import { LicenserProps } from 'Api';
import React from 'react';

export default function FooterNotice({ licenser }: LicenserProps) {
  // let email = <></>
  // if (props.email) {
  //   email = <>
  //   <div className="Footer-NoticeLine"></div>
  //   <div className="Footer-NoticeCommon TextLeft">
  //   本メールは送信専用ですので、ご返信いただいてもお答えできません。<br/><br/>
  //   本メールに心当たりが無い場合は、他の方がメールアドレスを誤入力してしまった可能性がございます。<br/><br/>
  //   万一このメールが誤って着信したものである場合は、破棄をお願いいたします。<br/><br/>
  //   誤って着信したメールを、自己のために利用したり、第三者に開示することを固く禁止します。<br/><br/>
  //   </div>
  //   </>
  // }
  return (
    <div className="Footer-Notice">
      <div className="Footer-NoticeCommon">
        弊社は{licenser.stationName!}から
        <br />
        権利処理業務を受託しております。
        <br />
        お問い合わせは、下記の権利処理担当者までお願いいたします。
        <br />
        <br />
        権利処理担当
        <br />
        ㈱シュヴァン　{licenser.stationName!}{licenser.purpose}チーム
        <br />
        Email：{licenser.innerEmail}
        <br />
        TEL：{licenser.assignee!.userTel}
        <br />
        <br />
      </div>
      {/* {email} */}
    </div>
  );
}
