import React from 'react';
import 'ViewComponents/ViewComponents.scss';
import './App.scss';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { StaffAuthProvider } from 'Data/StaffAuth';
import { LicenserPage, LicenserAdminPage } from 'Pages/LicenserPage';

function StaffAuthLicenserPage(props: any) {
  return (
    <StaffAuthProvider>
      <LicenserPage {...props} />
    </StaffAuthProvider>
  );
}
function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/l/:licenserViewId/:charge" component={StaffAuthLicenserPage} />
        <Route
          path="/ladmin/:licenserId/:newStatus"
          component={LicenserAdminPage}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
