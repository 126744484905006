import {
  getReairInfo,
  getReairInfoByAdmin,
  LicenserContentType,
  TypeReairLicenserReplyStatus,
  updateLicenserStatus,
} from 'Api';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';

export function useLicenserByAdmin(): any {
  const [status, setStatus] = useState('loading');
  const { licenserId, newStatus } = useParams<any>();
  const [licenser, setLicenser] = useState<LicenserContentType>({});
  useEffect(() => {
    const get = async () => {
      setStatus('loading');
      const licenserInfo = await getReairInfoByAdmin(licenserId);
      licenserInfo.replyStatus = newStatus;
      setLicenser(licenserInfo);
      setStatus('loaded');
    };
    get();
    // updateStatus('担当確認中', '権利確認メール送信済み');
    return () => {};
  }, [licenserId, newStatus]);

  return [status, licenser];
}

export function useLicenser(): any {
  const [status, setStatus] = useState('loading');
  const { licenserViewId } = useParams<any>();
  const [licenser, setLicenser] = useState<LicenserContentType>({});
  const updateStatus = useCallback(
    async (replyStatus: TypeReairLicenserReplyStatus, arg: any) => {
      setStatus('loading');
      const ret = await updateLicenserStatus(licenserViewId, replyStatus, arg);
      setLicenser(ret);
      console.log('setLicenser', ret);
      setStatus('loaded');
    },
    [licenserViewId],
  );
  useEffect(() => {
    setLicenser({ licenserViewId });
    const get = async () => {
      setStatus('loading');
      setLicenser(await getReairInfo(licenserViewId));
      setStatus('loaded');
    };
    get();
    return () => {};
  }, [licenserViewId, updateStatus]);

  return [status, licenser, updateStatus];
}
