import { useStaff } from 'Data/StaffAuth';
import React from 'react';
import Footer from 'ViewComponents/Footer';
import FooterCopyright from 'ViewComponents/Footer/FooterCopyright';
import Header from 'ViewComponents/Header';
import HeaderTitle from 'ViewComponents/Header/HeaderTitle';
import { Main } from 'ViewComponents/Main';
import { TextParagraph, TextThanks } from 'ViewComponents/TextParagraph';

export default function ErrorPage() {
  const staff = useStaff();
  console.log(staff);
  return (
    <div>
      <Header>
        <HeaderTitle />
      </Header>
      <Main noFooterNotice>
        <TextThanks large>エラーが発生しました。</TextThanks>
        <TextParagraph center>
          送付されたメールから操作をやり直してください。
          <br />
          <br />
        </TextParagraph>
      </Main>
      <Footer>
        <FooterCopyright>　©︎ 株式会社シュヴァン</FooterCopyright>
      </Footer>
    </div>
  );
}
