import { useLicenser, useLicenserByAdmin } from 'Data/Licenser';
import React, { useEffect, useState } from 'react';
import LoadingPage from './LoadingPage';
import { MutableLicenserProps } from 'Api';
import ChargeSelectPage from './ChargeSelectPage';
import { EventEmitter } from 'events';
import { NoChargeThanksSubmitted, NoChargeThanks, OKChargeThanks } from './ChargeThanks';
import ErrorPage from './ErrorPage';
import { ProjectDetail } from './ProjectDetail';
import { ProjectThanks } from './ProjectThanks';

function LicenserLoadedPage( props: MutableLicenserProps) {
  if (props.licenser.replyStatus === '担当確認回答待ち') {
    return <ChargeSelectPage {...props} />;
  } else if (props.licenser.replyStatus === '担当確認メール返信要確認') {
    return <NoChargeThanks {...props} />;
  } else if (props.licenser.replyStatus === '担当確認メール返信要確認_別担当者回答済み') {
    return <NoChargeThanksSubmitted {...props} />;
  } else if (props.licenser.replyStatus === '担当確認メール返信OK') {
    return <OKChargeThanks {...props} />;
  } else if (props.licenser.replyStatus === '権利確認回答待ち') {
    return <ProjectDetail {...props} />;
  } else if (props.licenser.replyStatus === '権利確認メール返信要確認' || props.licenser.replyStatus === '権利確認メール返信OK') {
    return <ProjectThanks {...props} />;
  }
  
  return <ErrorPage />
}
export function LicenserAdminPage(props: any) {
  const [statusEvent] = useState(new EventEmitter());
  const [status, licenser] = useLicenserByAdmin();

  useEffect(()=>{
    const statusOn = (arg:any) => {
      console.log(arg);
    };
    const contactSubmit = (message:string) => {
      console.log(message);
    }
    statusEvent.on("status", statusOn);
    statusEvent.on("contactSubmit", contactSubmit);
    const e = statusEvent;
    return () =>{
      e.off("status", statusOn)
      e.off("contactSubmit", contactSubmit);
    }
  }, [statusEvent, ])

  if (status === 'loading') {
    return <LoadingPage />;
  }

  return <LicenserLoadedPage licenser={licenser} statusEvent={statusEvent} />;
}
export function LicenserPage(props: any) {
  const [statusEvent] = useState(new EventEmitter());
  const [status, licenser, updateStatus] = useLicenser();

  useEffect(()=>{
    const statusOn = ({newStatus, comment}:any) => {
      updateStatus(newStatus, comment);
    };
    const contactSubmit = (message:string) => {
      console.log(message);
      updateStatus('担当確認メール返信要確認_別担当者回答済み', message);
    }
    statusEvent.on("status", statusOn);
    statusEvent.on("contactSubmit", contactSubmit);
    const e = statusEvent;
    return () =>{
      e.off("status", statusOn)
      e.off("contactSubmit", contactSubmit);
    }
  }, [statusEvent, updateStatus])

  if (status === 'loading') {
    return <LoadingPage />;
  }

  return <LicenserLoadedPage licenser={licenser} statusEvent={statusEvent} />;
}
