import axios from 'axios';
import { EventEmitter } from 'events';

export type TypeReairLicenserAdminStatus =
| '登録'
| '担当確認メール送信'
| '権利確認メール送信'
| 'コメント送信'
;
export type TypeReairLicenserReplyStatus =
| '未確認'
| '担当確認回答待ち'
| '担当確認メール返信OK'
| '担当確認メール返信要確認'
| '担当確認メール返信要確認_別担当者回答済み'
| '権利確認回答待ち'
| '権利確認メール返信要確認'
| '権利確認メール返信OK'
;
export type TypeReairLicenserResultStatus = "進行中" | "完了（OK）" | "完了（要確認）";

export interface CommentType {
  id: string;
  comment: string;
  speaker?: {
    name: {
      official: string;
    };
  };
  user?: {
    id: string;
  };
  updatedAt: string;
}

export interface AttachmentType {
  id?: number;
  filename?: string;
  url?: string;
  contentType?: string;
}
export interface LicenserContentType {
  licenserViewId?: string; // 000-000-000
  replyStatus?: TypeReairLicenserReplyStatus;

  stationName?: string; // ●●放送局
  stationExecutive?: string; // 局長　●● ●●
  belongOffice?: {
    officeName: string; // 事務所名
    staffName: string; // 事務所担当者名
  };

  purpose?: string; // チーム名？
  reairSubject?: string; // {連続テレビ小説「まれ」総集編　アンコール放送のお願い}
  reairTitle?: string; // {連続テレビ小説「まれ」総集編}
  licenseType?: string; // {ご出演}
  licenserName?: string; // {●● ●●}様
  airInfo?: string; // 放送日時
  airWaveInfo?: string; // 放送波情報
  dueDate?: number; // 確認期限
  dealMessage?: string; // 使用料
  attachments?: AttachmentType[]; // 添付ファイル情報
  reairMemo?: string; // 備考
  innerEmail?: string; // 内部連絡用メール

  comments?: CommentType[]; // コメント

  assignee?: {
    userName: string; // シュヴァン担当者名
    userEmail: string; // シュヴァン担当者メールアドレス
    userTel: string; // シュヴァン担当電話番号
  };
}

export interface StaffType {
  email: string;
  name: string;
}
export interface MutableLicenserProps {
  licenser: LicenserContentType;
  statusEvent: EventEmitter;
}
export interface LicenserProps {
  licenser: LicenserContentType;
}
const ACCESS_TOKEN_KEY = 'staffAccessToken';
const ADMIN_ACCESS_TOKEN_KEY = 'auth';
export function getStaffToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

export function saveToken(token: string) {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
}
export async function doLoginStaff(staffAuthToken: string): Promise<StaffType> {
  const res = await axios.post(
    `/api/creair/login`,
    {},
    {
      headers: { Authorization: `Bearer ${staffAuthToken}` },
    },
  );
  console.log('login', res);
  localStorage.setItem(ACCESS_TOKEN_KEY, res.data.access_token);
  return res.data.staff;
}
export async function doVerifyStaff(): Promise<StaffType> {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (!accessToken) {
    throw Error('not authorized');
  }
  const res = await axios.get(`/api/creair/verify`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  console.log('verify', res);
  //TODO save new token;
  return res.data.staff;
}
export async function getReairInfoByAdmin(
  licenserId: string,
): Promise<LicenserContentType> {
  const accessToken = localStorage.getItem(ADMIN_ACCESS_TOKEN_KEY);
  const res = await axios.get(`/api/creair/admin/${licenserId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  console.log(res);
  return res.data;
}

export async function getReairInfo(
  licenserViewId: string,
): Promise<LicenserContentType> {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  const res = await axios.get(`/api/creair/${licenserViewId}`, {
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  console.log(res);
  return res.data;
}
export async function getReairList(): Promise<LicenserContentType[]> {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (!accessToken) {
    throw new Error('not authorization');
  }
  const res = await axios.get(`/api/creair`, {
    params: {},
    headers: { Authorization: `Bearer ${accessToken}` },
  });
  return res.data.items;
}
export async function updateLicenserStatus(
  licenserViewId: string,
  replyStatus: TypeReairLicenserReplyStatus,
  message: string,
): Promise<LicenserContentType> {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  const res = await axios.put(
    `/api/creair/reply/${licenserViewId}`,
    {
      replyStatus,
      message,
    },
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    },
  );
  console.log(res);
  return res.data;
}
