import React from 'react';

function LeftArea(props: {children?:any}) {
  return <div style={{
    width: "349px", marginBottom: "28px"
  }}>
    {props.children}
  </div>
}
function HeightSpacer(props: {height:number}) {
  return <div style={{marginBottom: props.height + "px"}}></div>
}
function Main(props: {
  type?: 'detail' | 'normal' | 'mail';
  size?: 'limited-pc' | 'full' | 'mobile';
  paddingBottom? : number,
  noFooterNotice? : boolean,
  children?: any;
}) {
  let className = 'Main';
  if (!props.type || props.type === 'normal') {
    className += ' GrayBack';
  }
  const SizeClass = !props.size? "SizeFull" : (
    props.size === "limited-pc"? "SizeLimitedPC" : (
      props.size === "mobile"? "SizeMobile" : "SizeFull"
    )
  )
  className += " " + SizeClass;
  if (props.noFooterNotice) {
    className += " NoFooterNotice"
  }

  if (props.paddingBottom) {
    return <main className={className} style={{paddingBottom: props.paddingBottom+"px"}}>{props.children}</main>;
  } else {
    return <main className={className}>{props.children}</main>;
  }
}

export {Main, LeftArea, HeightSpacer};
