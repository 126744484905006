import React, { useState } from 'react';
import { ButtonL } from '../Parts/Buttons';
import { InputTextAreaNoCharge } from '../Parts/InputText';

export interface ContactFormSubmitArgs {
  chargeInfo: string;
}
export default function ContactForm(props: { children?: any, onSubmit?:(message:string) => void }) {
  const [chargeInfo, setChargeInfo] = useState('');

  const onSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();
    if (props.onSubmit) {
      props.onSubmit(chargeInfo);
    }
  };
  return (
    <form className="ContactForm" onSubmit={onSubmit}>
      <InputTextAreaNoCharge
        title="現在のご担当情報"
        value={chargeInfo}
        onChange={(ev: any) => {
          setChargeInfo(ev.target.value);
        }}
      />
      <ButtonL type="submit" title="送信" link="#" color="red" />
    </form>
  );
}
