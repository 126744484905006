import React from 'react';
import { CommentType, LicenserContentType } from 'Api';
import { textToJsx, toShortDateString } from 'Utils';

function CommentThreadReply(props: { index: number, children?: any; date: string }) {
  return (
    <>
      {props.index > 0 ? <div className="CommentThread-Line"></div> : <></> }
      <div className="CommentThread-Title">ライツビューから返信</div>
      <div className="CommentThread-Post">{props.children}</div>
      <div className="CommentThread-Date">{toShortDateString(props.date)}</div>
    </>
  );
}
function CommentElement({
  index,
  comment,
}: {
  index: number;
  comment: CommentType;
}) {
  if (comment.speaker) {
    return (
      <>
        <div className="CommentThread-Post">{textToJsx(comment.comment)}</div>
        <div className="CommentThread-Date">
          {toShortDateString(comment.updatedAt)}
        </div>
      </>
    );
  }
  return (
    <CommentThreadReply index={index} date={comment.updatedAt}>
      {textToJsx(comment.comment)}
    </CommentThreadReply>
  );
}

export default function CommentThread({
  licenser,
}: {
  licenser: LicenserContentType;
}) {
  if (!licenser.comments || licenser.comments.length === 0) {
    return <></>;
  }
  return (
    <div className="CommentThread">
      {licenser.comments.map((c, index: number) => (
        <CommentElement index={index} key={c.id} comment={c} />
      ))}
    </div>
  );
}
