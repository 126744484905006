import React from 'react';
import './Attachment.scss';
import fileIcon from './images/att-file.svg';


export default function Attachment(props:{
  name:string,
  url: string,
  contentType: string,
}) {
  return <a href={props.url} className="Attachment">
    <img src={fileIcon} className="Attachment-Img" alt={props.name}/>
    <label className="Attachment-Label" >{props.name}</label>
  </a>
}