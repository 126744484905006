import React from 'react';
import Footer from 'ViewComponents/Footer';
import FooterCopyright from 'ViewComponents/Footer/FooterCopyright';
import Header from 'ViewComponents/Header';
import HeaderTitle from 'ViewComponents/Header/HeaderTitle';
import { Main } from 'ViewComponents/Main';

export default function LoadingPage() {
  return (
    <div>
      <Header>
        <HeaderTitle />
      </Header>
      <Main noFooterNotice={true}>
      <div id="loading" className="contents">
        <i></i>
      </div>
      </Main>
      <Footer>
        <FooterCopyright>　©︎ 株式会社シュヴァン</FooterCopyright>
      </Footer>
    </div>
  );
}

