import './InputText.scss';

export function InputTextAreaNoCharge(props: {
  title: string;
  value?: any;
  onChange?: any;
}) {
  return (
    <div className="InputTextAreaNoChargeEl">
      <label>
      {props.title}
      <textarea
        className="InputTextAreaNoChargeEl-Input"
        value={props.value}
        onChange={props.onChange}
      ></textarea>
      </label>
    </div>
  );
}

export function InputTextArea(props: {
  title: string,
  placeholder: string;
  value?: any;
  onChange?: any;
}) {
  return (
    <div className="InputTextAreaEl">
      <label>
      {props.title}
      <textarea
        className="InputTextAreaEl-Input"
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
      ></textarea>
      </label>
    </div>
  );
}
export function InputText(props: {
  title: string;
  placeholder: string;
  type?: string;
  value?: any;
  onChange?: any;
}) {
  const inputType = props.type ? props.type : 'text';
  return (
    <div className="InputTextEl">
      <div className="InputTextEl-Title">{props.title}</div>
      <input
        type={inputType}
        className="InputTextEl-Input"
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.onChange}
      ></input>
    </div>
  );
}
