import React from 'react';
import { ButtonM } from '../Parts/Buttons';
import { toDateString } from '../../Utils';
import { LicenserContentType } from 'Api';

function GotoDetail() {
  return (
    <div className="GotoDetail">
      <ButtonM title="詳細確認と回答" link="#" color="red" />
    </div>
  );
}
function ProjectNumber(props: { projectId: string }) {
  const str = '案件番号：' + props.projectId;
  return (
    <div className="ProjectNumber">
      <span>{str}</span>
    </div>
  );
}

function AnswerMark(props: { alert: boolean }) {
  if (props.alert) {
    return <div className="AnswerMark">未回答</div>;
  } else {
    return <div className="AnswerMark DoneColor">OK回答済み</div>;
  }
}
function AnswerStatus(props: { alert: boolean; deadline: number }) {
  const str = toDateString(props.deadline) + 'までにご回答ください';

  return (
    <div className="AnswerStatus">
      <AnswerMark alert={props.alert} />
      <span>{str}</span>
    </div>
  );
}
function SummaryDesc(props: { subject: string; content: string }) {
  return (
    <div className="SummaryDesc">
      <div className="SummaryDesc-Subject">{props.subject}</div>
      <div className="SummaryDesc-Content">{props.content}</div>
      <div className="SummaryDesc-Border"></div>
    </div>
  );
}
function SummaryCard({ licenser }: { licenser: LicenserContentType }) {
  return (
    <div className="SummaryCard">
      <SummaryDesc
        subject={licenser.stationName!}
        content={licenser.reairSubject!}
      />
      <SummaryDesc subject="番組名" content={licenser.reairTitle!} />
      <SummaryDesc
        subject={licenser.licenseType!}
        content={`${licenser.licenserName!} 様`}
      />
      <ProjectNumber projectId={licenser.licenserViewId!} />
    </div>
  );
}

function SummaryCardList(props: { children?: any }) {
  return <div className="SummaryCardList">{props.children}</div>;
}
export {
  SummaryCardList,
  SummaryCard,
  AnswerStatus,
  SummaryDesc,
  ProjectNumber,
  GotoDetail,
};
