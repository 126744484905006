import { MutableLicenserProps } from 'Api';
import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import LoadingPage from './LoadingPage';

export default function ChargeSelectPage({ licenser, statusEvent }: MutableLicenserProps) {
  const { charge } = useParams<any>();
  useEffect(()=>{
    statusEvent.emit('status', {
      newStatus: charge === "charge" ?  "担当確認メール返信OK" : "担当確認メール返信要確認"
    })
  }, [licenser, statusEvent, charge])
  return (
    <LoadingPage />
  )
}
