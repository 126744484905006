import React from 'react';
import { useHistory } from 'react-router-dom';
import './Buttons.scss';

interface ButtonProps {
  sizeClass?: string,
  title: string;
  link?: string;
  onClick?: any;
  disabled? : boolean
  color: 'red' | 'blue';
  type?:any,
}

function ButtonBase(props: ButtonProps) {
  // const history = useHistory();
  // const onClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  //   console.log('onClick');
  //   history.push(props.link);
  //   ev.preventDefault();
  // };
  const className = props.sizeClass + ' ButtonBase ButtonBase-' + props.color;
  const buttonType = props.type ? props.type : "button";
  if (props.onClick) {
    return (
      <button type={buttonType} className={className} onClick={props.onClick} disabled={props.disabled}>
        <div className="ButtonBase-Title">{props.title}</div>
      </button>
    );
  } 
  return (
    <button type={buttonType} className={className} /*onClick={onClick}*/ disabled={props.disabled}>
      <div className="ButtonBase-Title">{props.title}</div>
    </button>
  );
}
function ButtonM(props: ButtonProps) {
  return <ButtonBase sizeClass="ButtonM" {...props}/>
}
function ButtonL(props: ButtonProps) {
  return <ButtonBase sizeClass="ButtonL" {...props}/>
}
function ButtonL2(props: {
  title: string;
  desc: string;
  onClick?: any;
  link?: string;
  color: 'red' | 'blue';
}) {
  const history = useHistory();
  const onClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (props.onClick) {
      props.onClick();
    } else if (props.link) {
      history.push(props.link);
    }
    ev.preventDefault();
  };
  const className = 'ButtonL2 ButtonBase ButtonBase-' + props.color;

  return (
    <button type="button" className={className} onClick={onClick}>
      <div className="ButtonBase-Title">{props.title}</div>
      <div className="ButtonBase-Desc">{props.desc}</div>
    </button>
  );
}

function WriteButton( props: {title:string} ) {
  const onClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    console.log('WriteButton onClick')
  };

  return (
    <button type="button" className="WriteButton" onClick={onClick}>
      <div className="WriteButton-Title">{props.title}</div>
    </button>
  );
}
export { ButtonL2, ButtonL, ButtonM, WriteButton};
