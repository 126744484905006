import React from 'react';

function DevideLine() {
  return <div className="TextParagraph-Line"></div>;
}
function TextThanks(props: { large?: boolean; children?: any }) {
  let className = 'TextThanks';
  if (props.large) {
    className += ' TextThanks-Large';
  }
  return <div className={className}>{props.children}</div>;
}
function TextParagraph(props: { center?: boolean; style?:any, children?: any }) {
  let className = 'TextParagraph';
  if (props.center) {
    className += ' TextCenter';
  }
  if (props.style) {
    return <div className={className} style={props.style}>{props.children}</div>;
  } else {
    return <div className={className}>{props.children}</div>;
  }
}

export { TextParagraph, TextThanks, DevideLine };
