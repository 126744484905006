
import React from 'react';

export function toDateString( date?: number ) {
  return (new Date(date!)).toLocaleDateString('ja-JP', {
    year: 'numeric', month: 'short', day: 'numeric', weekday:'long'
  });
}



export function textToJsx(str:string) : any[] {
  const lines: any = [];
  if (!str) return [];

  str.split("\n").forEach( (line: string, index:number) => {
    const el = <div key={index}>{line}</div>;
    lines.push(el);
  });
  return lines;
}
export function toShortDateString(dateS: string) {
  const date = new Date(Date.parse(dateS));
  const yyyy = date.getFullYear();
  const M = date.getMonth() + 1;
  const D = date.getDate();
  const h = date.getHours();
  const m = date.getMinutes();

  return `${yyyy}/${M}/${D} ${h}:${m}`;
}
