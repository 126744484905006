import React from 'react';

function Section(props: any) {

  return <div className="Section" >
    <div className="Section-Title">{props.title}</div>
    <div className="Section-Line"></div>
    <div className="Section-Children">
      {props.children}
    </div>
  </div>
}

export default Section;