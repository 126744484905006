import { getStaffToken, MutableLicenserProps } from 'Api';
import React, { useMemo } from 'react';
import Footer from 'ViewComponents/Footer';
import FooterCopyright from 'ViewComponents/Footer/FooterCopyright';
import FooterNotice from 'ViewComponents/Footer/FooterNotice';
import Header from 'ViewComponents/Header';
import HeaderTitle from 'ViewComponents/Header/HeaderTitle';
import { Main } from 'ViewComponents/Main';
import AnswerSection from 'ViewComponents/AnswerSection';
import Attachment from 'ViewComponents/Parts/Attachment';
import Section from 'ViewComponents/Parts/Section';
import ProjectGuide from 'ViewComponents/ProjectGuide';
import { textToJsx } from 'Utils';

export function ProjectDetail({ licenser, statusEvent }: MutableLicenserProps) {
  const attachments = useMemo(() => {
    if (!licenser.attachments) return [];
    return licenser.attachments;
  }, [licenser]);
  return (
    <div>
      <Header>
        <HeaderTitle />
        {/* <HeaderNavi prev={{ name: '案件一覧', url: '#' }}>案件詳細</HeaderNavi> */}
      </Header>
      <Main type="detail" size="limited-pc">
        <ProjectGuide>
          {licenser.belongOffice!.officeName}
          <br />
          {licenser.belongOffice!.staffName}様
          <br />
          <br />
          平素は大変お世話になっております。
          <br />
          貴社益々ご清祥のこととお慶び申し上げます。
          <br />
          {licenser.stationName!}
          {licenser.purpose}チームです。 <br />
          <br />
          このたびは、番組制作に貴重なご協力を賜り、厚く御礼申し上げます。
          <br />
          下記をご高覧いただき、ご確認賜りたく、何卒宜しくお願い申し上げます。
          <br />
        </ProjectGuide>
        <Section title="番組名">{licenser.reairTitle!}</Section>
        <Section title={licenser.licenseType}>
          {licenser.licenserName!}様
        </Section>
        <Section title="放送日時（予定）">
          {textToJsx(licenser.airInfo!)}
          <br />
          {textToJsx(licenser.airWaveInfo!)}
        </Section>
        <Section title="使用料">{textToJsx(licenser.dealMessage!)}</Section>
        <Section title="添付">
          {attachments.map((e) => (
            <Attachment
              key={e.id}
              name={e.filename!}
              url={`/api/attachment/${e.id}?t=${getStaffToken()}`}
              contentType={e.contentType!}
            />
          ))}
        </Section>
        <Section title="備考">{textToJsx(licenser.reairMemo!)}</Section>
        <Section title="案件番号">{licenser.licenserViewId!}</Section>
        <Section title="ご回答">
          <AnswerSection licenser={licenser} statusEvent={statusEvent} />
        </Section>
      </Main>
      <Footer>
        <FooterNotice licenser={licenser} />
        <FooterCopyright>　©︎ 株式会社シュヴァン</FooterCopyright>
      </Footer>
    </div>
  );
}
