import { MutableLicenserProps } from 'Api';
import React from 'react';
import Footer from 'ViewComponents/Footer';
import FooterCopyright from 'ViewComponents/Footer/FooterCopyright';
import FooterNotice from 'ViewComponents/Footer/FooterNotice';
import Header from 'ViewComponents/Header';
import HeaderTitle from 'ViewComponents/Header/HeaderTitle';
import { Main } from 'ViewComponents/Main';
import ProjectGuide, { ProjectGuideCenter } from 'ViewComponents/ProjectGuide';
import { TextParagraph, TextThanks } from 'ViewComponents/TextParagraph';

export function ProjectThanks({ licenser, statusEvent }: MutableLicenserProps) {
  let desc = <></>;
  if (licenser.replyStatus! === '権利確認メール返信OK') {
    desc = (
      <>
        いただいたご回答をもって進めさせていただきます。
        <br />
        ご確認用にご回答内容をメールさせていただきます。
        <br />
        今後ともよろしくお願いいたします。
        <br />
      </>
    );
  } else {
    desc = (
      <>
        ご確認用にご回答内容をメールさせていただきます。
        <br />
        改めてこちらからご連絡を差し上げる場合がございますのでご了承ください。
        <br />
      </>
    );
  }
  return (
    <div className="ProjectThanks">
      <Header>
        <HeaderTitle />
      </Header>
      <Main>
        <ProjectGuideCenter>
          <TextParagraph>
            {licenser.belongOffice!.officeName}
            <br />
            {licenser.belongOffice!.staffName}様
            <br />
            <br />
            ご回答ありがとうございました。
            <br />
            {desc}
          </TextParagraph>
        </ProjectGuideCenter>
      </Main>
      <Footer>
        <FooterNotice licenser={licenser} />
        <FooterCopyright>　©︎ 株式会社シュヴァン</FooterCopyright>
      </Footer>
    </div>
  );
}
