import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { /*useHistory, */useLocation, useParams } from 'react-router';
import {doLoginStaff, doVerifyStaff, saveToken, StaffType} from 'Api'
import ErrorPage from 'Pages/ErrorPage';
import LoadingPage from 'Pages/LoadingPage';

type LoginStatusType = 'init' | 'checkStorage' | 'login' | 'error';

interface StaffAuthContextType {
  loginStatus : LoginStatusType;
  charge: boolean
  staff? : StaffType
}
const StaffAuthContext = createContext<StaffAuthContextType>(
  {} as StaffAuthContextType
);
// 各 component から userState を呼び出す為の function
export const useStaff = () => {
  return useContext(StaffAuthContext);
};

function StaffAuthProvider({ children }: any) {
  const [loginStatus, setLoginStatus] = useState<LoginStatusType>('init');
  const [staff, setStaff] = useState<StaffType>();
  const loc = useLocation();
  const {charge} = useParams<any>();

  // const history = useHistory();
  const searchParams = useMemo(() => {
    return new URLSearchParams(loc.search);
  }, [loc]);
  // console.log(loc, history, p, 'p.get', p.get('tk'));
  // // window.location.search = '';
  // p.delete('tk');
  useEffect(() => {
    const init2 = async () => {
      const authToken = searchParams.get('tk');
      if (!authToken) {
        // すでに保存しているStorageで対応する
        setLoginStatus("checkStorage");
        return;
      }
      saveToken(authToken);
      window.location.replace(window.location.href.substr(0, window.location.href.indexOf("?")));
    }
    async function checkStorage() {
      try {
        setStaff(await doVerifyStaff())
        setLoginStatus("login");
      } catch(e) {
        setLoginStatus("error");
      }
    }
    if (loginStatus === 'init') {
      init2();
    } else if (loginStatus === 'checkStorage') {
      checkStorage();
    }
  }, [loginStatus, searchParams]);
  let body = children;
  if (loginStatus === 'error') {
    body = <ErrorPage/>
  } else 
  if (loginStatus !== 'login') {
    body = <LoadingPage/>
  }
  return (
    <StaffAuthContext.Provider value={{
      loginStatus: loginStatus,
      charge: charge === "charge" ? true : false,
      staff: staff,
      }}>
      {body}
    </StaffAuthContext.Provider>
  );
}

export { StaffAuthContext, StaffAuthProvider };
