import React from 'react';

export default function ProjectGuide(p: any) {
  return (
    <div className="ProjectGuide">
      <div className="ProjectGuide-Content">{p.children}</div>
    </div>
  );
}
export function ProjectGuideCenter(p: any) {
  return (
    <div className="ProjectGuideCenter">
      <div className="ProjectGuide-Content">{p.children}</div>
    </div>
  );
}
